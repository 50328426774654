<template>
  <div>
    <header-slot></header-slot>
    <b-nav card-header pills class="m-0">
      <!-- Tab Loyal -->
      <b-nav-item
        :to="{ name: 'administration-status-change-loyal-tab' }"
        :active="isActiveTab(1)"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Loyal
      </b-nav-item>

      <!-- TAB Hold -->
      <b-nav-item
        :to="{ name: 'administration-status-change-hold-tab' }"
        :active="isActiveTab(2)"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Hold
        <span
          v-if="
            counterHoldTransitionClients > 0 &&
            moduleId == 4 &&
            (roleId == 1 || roleId == 2)
          "
          class="ml-1"
        >
          <feather-icon
            icon
            :badge="
              counterHoldTransitionClients > 99
                ? '99+'
                : counterHoldTransitionClients
            "
            badge-classes="badge-danger "
          />
        </span>
      </b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import StatusChangeService from "@/views/crm/views/status-change/service/status-change.service";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      counterHoldClients: 0,
      counterHoldTransitionClients: 0,
    };
  },
  computed: {
    routerName() {
      return this.$route.matched[2].name;
    },
    moduleId: function () {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    roleId: function () {
      return this.currentUser.role_id;
    },
  },
  created() {
    if (this.moduleId !== 6) {
      this.getCounter();
    }
  },
  methods: {
    isActiveTab(tab) {
      if (
        tab == 1 &&
        this.routerName == "administration-status-change-loyal-tab"
      ) {
        return true;
      } else if (
        tab == 2 &&
        this.routerName == "administration-status-change-hold-tab"
      ) {
        return true;
      }
      return false;
    },
    getCounter: async function () {
      const data = await StatusChangeService.getCounterHoldClients();
      this.counterHoldTransitionClients = await data;
    },
  },
};
</script>

<style>
.bg-tab {
  background-color: #0090e7;
}
</style>
